<template>
  <el-container class="container">
    <el-container class="main-container">
      <el-header class="header" height="2.70833vw">
        <div class="header-left">
          <div class="header-title">
            <div class="header-title-icon" />
            订单记录
          </div>
          <!-- <div class="header-btn">账单记录</div> -->
          <span class="header-text">需要开票请联系客服：0755-8633-6555</span>
        </div>
        <!-- <div class="header-right">
          <Search
            v-model="keyword"
            @search="handleSearch"
            placeholder="搜索订单"
          />
        </div> -->
      </el-header>
      <el-main class="main">
        <el-table :data="tableData">
          <el-table-column label="订单时间" prop="createdTime" />
          <el-table-column label="订单编号" prop="number" />
          <el-table-column label="数额">
            <template slot-scope="scope">
              <span>￥{{ scope.row.money }}</span>
            </template>
          </el-table-column>
          <el-table-column label="详情" prop="remark" />
          <el-table-column label="状态">
            <template slot-scope="scope">
              <p :class="['state', 'state-' + scope.row.state]">
                {{ scope.row.stateName }}
              </p>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
    </el-container>
    <!-- 右侧升级信息 -->
    <el-aside class="aside" width="20.3125vw">
      <div class="aside-top">
        <img src="~a/workbench/ad.png" alt="" />
        <router-link class="aside-top-btn" to="/serviceUpgrade"
          >立即购买升级服务</router-link
        >
      </div>
      <div class="aside-bottom">
        <h1 class="aside-bottom-title">企业定制专享版</h1>
        <ul class="aside-bottom-list">
          <li class="aside-bottom-list-item">
            <span class="circle"></span>
            <p>独立服务器、数据库</p>
          </li>
          <li class="aside-bottom-list-item">
            <span class="circle"></span>
            <p>私有化、本地化部署</p>
          </li>
          <li class="aside-bottom-list-item">
            <span class="circle"></span>
            <p>数据集、储存空间等规格定制化</p>
          </li>
          <li class="aside-bottom-list-item">
            <span class="circle"></span>
            <p>专享宽带</p>
          </li>
        </ul>
        <button class="aside-bottom-btn" title="0755-8633-6555">
          <img src="~a/workbench/concatMe.png" alt="" />
          <span class="aside-bottom-btn-text">联系我们</span>
        </button>
      </div>
    </el-aside>
  </el-container>
</template>

<script>
// import Search from 'c/workbench/Search';
export default {
  name: 'BuyRecord',
  // components: {
  //   Search
  // },
  data() {
    return {
      keyword: '',
      tableData: []
    };
  },
  created() {
    this.tableData = [];
  },
  methods: {
    handleSearch() {}
  }
};
</script>

<style lang="less" scoped>
.container {
  border-radius: 0 !important;
  padding: 0 !important;
  margin-right: 0 !important;
  background-color: transparent !important;
  .main-container,
  .aside {
    background-color: #fff;
  }
  .main-container {
    border-radius: 10px;
    padding: 20px 28px;
  }
  .aside {
    padding-top: 16px;
    margin-left: 20px;
    border-radius: 10px 0 0 10px;
    &-top {
      position: relative;
      width: 358px;
      height: 388px;
      margin: 0 auto 18px auto;
      &-btn {
        position: absolute;
        left: 50%;
        bottom: 20px;
        transform: translateX(-50%);
        width: 249px;
        height: 47px;
        text-align: center;
        line-height: 47px;
        background: linear-gradient(135deg, #e6c085 0%, #e9c795 100%);
        border-radius: 24px;
        color: #78482f;
        font-size: 22px;
        font-weight: bold;
      }
    }
    &-bottom {
      box-sizing: border-box;
      width: 358px;
      height: 303px;
      padding: 20px 34px;
      margin: 0 auto;
      background-image: url(~a/workbench/buyRecord-aside-bottom-bg.png);
      background-size: cover;
      &-title {
        display: inline-block;
        margin-bottom: 32px;
        background: linear-gradient(180deg, #e9cfab 0%, #d29f6d 100%);
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 28px;
        font-weight: bold;
      }
      &-list {
        margin-bottom: 20px;
        color: #e7c19f;
        font-size: 16px;
        &-item {
          display: flex;
          align-items: center;
          margin-bottom: 8px;
          &:last-child {
            margin-bottom: 0;
          }
          .circle {
            display: inline-block;
            width: 4px;
            height: 4px;
            margin-right: 12px;
            background: #e7c19f;
            border-radius: 2px;
          }
        }
      }
      &-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 180px;
        height: 50px;
        background: #776a6a;
        border-radius: 25px;
        margin: 0 auto;
        &-text {
          margin-left: 10px;
          background: linear-gradient(180deg, #ffdeb1 0%, #e5ab72 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-size: 22px;
          font-weight: bold;
        }
      }
    }
  }
}

.header {
  margin-bottom: 12px;
}

.header-text {
  color: #666666;
  font-size: 16px;
}

// 表格里状态
.state {
  width: 95px;
  height: 33px;
  line-height: 33px;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 6px;
  &-1 {
    border-color: #33d742;
    background-color: rgba(51, 215, 66, 0.1);
    color: #33d742;
  }
}
</style>
